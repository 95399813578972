import FavoriteEvent from '@adobe-fonts/site/events/FavoriteEvent.js';
import NotificationEvent from '@adobe-fonts/site/events/NotificationEvent.js';
import { addFavorite, removeFavorite } from '../../../util/variation_favorite.js';
import { getData } from '../../../util/script_data.js';
import { getService as service } from '../../../util/angular_helpers.js';
import { sendAnalyticsEventWithPage } from '../../../util/new_relic.js';

export class VariationCardFavorite {
  constructor(element) {
    this.element = element;
    this.favoriteButton = null;
    this.analyticsAttributes = {};
  }

  getI18n = () => {
    return ((getData('/neue/i18n') || {})['neue.browse.favorites'] || {});
  };

  sendNotification = (params) => {
    if (!this.shouldShowNotification()) {
      return;
    }
    window.dispatchEvent(new NotificationEvent(params));
  };

  handleFavoriteEvent = async (event) => {
    const button = event.target;
    const card = button.closest('af-variation-card') || button.closest('[data-id="font-variation-card"]');

    if (!card || !('fontVariationId' in card.dataset || 'id' in card.dataset)) {
      return;
    }
    this.favoriteButton = button;

    const id = card.dataset.fontVariationId || card.dataset.id;
    const shouldAdd = !this.favoriteButton.hasAttribute('selected');
    this.analyticsAttributes['fontVariationId'] = id;

    if (shouldAdd) {
      await this._handleAddFavorite(id);
    } else {
      await this._handleRemoveFavorite(id);
    }
  };

  _handleAddFavorite = async (id) => {
    try {
      await this.handleAuthenticationWithPostLoginAction('favorite_fonts', {font_ids: [parseInt(id, 10)]});
      await this._addFavorite([id]);
      this.favoriteButton.toggleAttribute('selected', true);
      this._sendAddSuccessNotification();
      sendAnalyticsEventWithPage('add-favorite', this.analyticsAttributes);
    } catch (error) {
      console.error(error);
      this._sendAddErrorNotification();
    }
  };

  handleAuthenticationWithPostLoginAction = async (action, data) => {
    const AuthenticationService = service('AuthenticationService');
    await AuthenticationService.handleAuthenticationWithPostLoginAction(action, data);
  };

  _addFavorite = async (ids) => {
    await addFavorite(ids);
  };

  _sendAddSuccessNotification = () => {
    const i18n = this.getI18n();
    this.sendNotification({
      type: 'success',
      message: i18n['add_favorite_success']
    });
  };

  _sendAddErrorNotification = () => {
    const i18n = this.getI18n();
    this.sendNotification({
      type: 'error',
      message: i18n['add_favorite_failure']
    });
  };

  _handleRemoveFavorite = async (id) => {
    try {
      await this._removeFavorite([id]);
      this.favoriteButton.removeAttribute('selected');
      this._sendRemoveSuccessNotification();
      sendAnalyticsEventWithPage('remove-favorite', this.analyticsAttributes);
    } catch (error) {
      console.error(error);
      this._sendRemoveErrorNotification();
    }
  };

  _removeFavorite = async (ids) => {
    await removeFavorite(ids);
  };

  _sendRemoveSuccessNotification = () => {
    const i18n = this.getI18n();
    this.sendNotification({
      type: 'success',
      message: i18n['remove_favorite_success'],
      options: {
        actionLabel: i18n['remove_favorite_undo'],
        callbackAction: () => this.handleUndoRemoveFavorite()
      }
    });
  };

  _sendRemoveErrorNotification = () => {
    const i18n = this.getI18n();
    this.sendNotification({
      type: 'error',
      message: i18n['remove_favorite_failure']
    });
  };

  shouldShowNotification = () => {
    // Only show toast notification for library show page
    return !!this.element.closest('af-library-show-page');
  };

  handleUndoRemoveFavorite = () => {
    sendAnalyticsEventWithPage('undo-remove-favorite', this.analyticsAttributes);
    this.favoriteButton.dispatchEvent(new FavoriteEvent());
  };
}

export function registerEventHandlers(element) {
  const variationCardFavorite = new VariationCardFavorite(element);
  element.addEventListener(FavoriteEvent.EVENT_NAME, variationCardFavorite.handleFavoriteEvent);
}
